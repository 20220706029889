import React, { useEffect } from "react";
import jaatis from "../../assets/newGallery/20.jpg";
import ralli from "../../assets/photographerGallery/rallitool.jpg";
import projektor from "../../assets/photographerGallery/projektor.jpg";
import { FaParking } from "react-icons/fa";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
import { GiClothes } from "react-icons/gi";
import { PiCoffeeFill } from "react-icons/pi";

import "../../style.css";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="">
      <div className="container d-flex flex-column align-items-center py-5">
        <div className="row home mb-5">
          <div className="col-lg-6 col-md-12 d-flex flex-column text-center d-md-block mt-4 mb-5 white-wrapper">
            <h1 className="h1">
              <span className="purple-text">Sweetheart</span> mängutuba
              Tallinnas
            </h1>
            <h3 className="hero-small-text pb-3">
              Lõbus ja meeldejääv lapse sünnipäev!
            </h3>

            <Link to="/broneerimine">
              <button className="btn btn-lg change-bg" type="button">
                Broneeri stuudio
              </button>
            </Link>
            <div className="row row-cols-2 g-3">
              <div className="col d-flex align-items-center">
                <FaParking size={"2rem"} title="parkimine" />
                <span className="mx-2">Tasuta parkimine</span>
              </div>
              <div className="col d-flex align-items-center">
                <CgSmartHomeRefrigerator size={"2rem"} title="külmkapp" />
                <span className="mx-2">Suur külmkapp tordile</span>
              </div>
              <div className="col d-flex align-items-center">
                <GiClothes size={"2rem"} title="konditsioneer" />
                <span className="mx-2">Kostüümid poistele ja tüdrukutele</span>
              </div>
              <div className="col d-flex align-items-center">
                <PiCoffeeFill size={"2rem"} title="kohv" />
                <span className="mx-2">Tasuta kohv</span>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <h2 className="hero-h2">Sünnipäev - Filmiõhtu - Playdate</h2>
          <p>
            Sweetheart mängu- ja peostuudio on armas hubane kohake, mis on
            <br />
            südamega loodud Sinu kõige kallimate sündmuste tähistamiseks.
          </p>
          <ul>
            <li>Asume Mustamäe ja Kristiine piiril Tallinnas.</li>
            <li>
              Mängutuba võimaldab pidada lapse/laste sünnipäeva kuni 40
              inimesega
            </li>
            <li>Korraldada noorte filmiõhtuid kuni 12-le</li>
            <li>Mänguhommikud beebidele</li>
          </ul>
          <Link to={"/tutvustus"}>
            <span className="black-link">Loe kõigest lähemalt</span>
          </Link>{" "}
          või{" "}
          <Link to={"/galerii"}>
            <span className="black-link"> vaata galeriid</span>
          </Link>
        </div>

        <div className="p-5">
          <h3>Uued võimalused mängustuudios</h3>
          <div className="row">
            <div className="col-lg-4 col-md-12 d-flex justify-content-center pt-5 py-3 ">
              <div
                className="card"
                style={{ maxWidth: "25rem", borderColor: "#8d0a99" }}
              >
                <Link to={"/pehmejaatis"}>
                  <img
                    src={jaatis}
                    className="card-img-top"
                    alt="Pehmejäätisemasin ja toidukaunistused lauapeal"
                  />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Pehmejäätisemasina rent</h5>
                  <p className="card-text">
                    Pehme jäätis koos kaunistustega nautimiseks nii lastele kui
                    ka täiskasvanutele.
                  </p>
                  <Link to={"/pehmejaatis"}>
                    <span className="black-link">Rohkem infot</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 d-flex justify-content-center pt-5 py-3">
              <div
                className="card"
                style={{ maxWidth: "25rem", borderColor: "#8d0a99" }}
              >
                <Link to={"/tutvustus"}>
                  <img
                    src={ralli}
                    className="card-img-top"
                    alt="Rallitool koos rooli ja pedaalidega. Seina peal on televiisor, kus on näha videomängu"
                  />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Rallitool</h5>
                  <p className="card-text">
                    Rallisimulaatoril saavad ennast proovile panna kõik, kellele
                    meeldivad autod ja kiirus.
                  </p>
                  <p>
                    Täpsem info on leitav mängutoa{" "}
                    <Link to={"/tutvustus"}>
                      <span className="black-link">tutvustuse lehel</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex justify-content-center pt-5 py-3">
              <div
                className="card"
                style={{ maxWidth: "25rem", borderColor: "#8d0a99" }}
              >
                <Link to={"/tutvustus"}>
                  <img
                    src={projektor}
                    className="card-img-top"
                    alt="Projektoriekraan, millel on Just Dance mäng"
                  />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Projektor</h5>
                  <p className="card-text">
                    Võimalik vaadata rahulikult filmi või panna end koos
                    sõpradega proovile tantsumängus Just Dance.
                  </p>
                  <p>
                    Täpsem info on leitav mängutoa{" "}
                    <Link to={"/tutvustus"}>
                      <span className="black-link">tutvustuse lehel</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
