import React, { useEffect } from "react";

import kooker1 from "../../assets/kooker1.webp";
import kooker2 from "../../assets/kooker2.webp";
import kooker3 from "../../assets/kooker3.webp";

const Kooker = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="container py-5">
      <h2>Kooker pannkoogid</h2>
      <div className="row white-wrapper">
        <div className="col-lg-6 col-md-12 ">
          <p>
            <b>Kooker</b> on laste sünnipäevadel väga oodatud külaline. Kooker
            valmistab soolaseid ja magusaid minipannkooke, mis maitsevad nii
            suurtele kui väikestele. Väikestel on eriti mugav koogid näppude
            vahele haarata ja edasi lustida. Kooker toob kuumad koogid otse
            pannilt sinu peolauale ning sulle jääb ainult serveerimise- ja
            nautimise rõõm. Kookeris pakitakse kaasa ka kõik serveerimiseks
            vajalikud vahendid.
          </p>
          <p>
            <b>Telli mugavalt</b> koogid Kookeri e-poest ja ajasta tellimus
            endale sobivaks päevaks- ja kellaajaks ning haara koogid kaasa või
            kasuta kullerteenust. Kooker asub mängutoast kõigest 170m kaugusel
            ehk koogid saab peaaegu üle ukse.
          </p>
          <p>
            <b>
              Pea oma pidu Sweetheart mängu-ja peostuudios ning meie anname
              Sulle Kooker e-poe sooduskoodi -15% !
            </b>
          </p>
          <p className="pt-4">
            Vaata täpsemalt:{" "}
            <a
              className="black-link"
              href="https://kooker.ee/e-pood/"
              target="_blank"
            >
              https://kooker.ee/e-pood/
            </a>
          </p>
          <p>
            Instagram:{" "}
            <a
              className="black-link"
              href="https://www.instagram.com/kookerstreetcafe/"
              target="_blank"
            >
              https://www.instagram.com/kookerstreetcafe/
            </a>
          </p>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic"
            src={kooker1}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
      </div>
    </div>
  );
};

export default Kooker;
