import ImageGallery from "react-image-gallery";
import pic1 from "../../assets/photographerGallery/1.jpg";
import pic2 from "../../assets/photographerGallery/2.jpg";
import pic3 from "../../assets/photographerGallery/3.jpg";
import pic4 from "../../assets/photographerGallery/4.jpg";
import pic5 from "../../assets/photographerGallery/5.jpg";
import pic6 from "../../assets/photographerGallery/6.jpg";
import pic7 from "../../assets/photographerGallery/7.jpg";
import pic8 from "../../assets/photographerGallery/8.jpg";
import pic9 from "../../assets/photographerGallery/9.jpg";
import pic10 from "../../assets/photographerGallery/10.jpg";
import pic11 from "../../assets/photographerGallery/11.jpg";
import pic12 from "../../assets/photographerGallery/12.jpg";
import pic13 from "../../assets/photographerGallery/13.jpg";
import pic14 from "../../assets/photographerGallery/14.jpg";
import pic101 from "../../assets/photographerGallery/101.jpg";
import pic102 from "../../assets/photographerGallery/102.jpg";
import pic103 from "../../assets/photographerGallery/103.jpg";
import pic104 from "../../assets/photographerGallery/104.jpg";
import pic105 from "../../assets/photographerGallery/105.jpg";
import pic106 from "../../assets/photographerGallery/106.jpg";
import pic107 from "../../assets/photographerGallery/107.jpg";
import pic108 from "../../assets/photographerGallery/108.jpg";
import pic109 from "../../assets/photographerGallery/109.jpg";

import thumbnail1 from "../../assets/photographerGallery/thumbnails/1.jpg";
import thumbnail2 from "../../assets/photographerGallery/thumbnails/2.jpg";
import thumbnail3 from "../../assets/photographerGallery/thumbnails/3.jpg";
import thumbnail4 from "../../assets/photographerGallery/thumbnails/4.jpg";
import thumbnail5 from "../../assets/photographerGallery/thumbnails/5.jpg";
import thumbnail6 from "../../assets/photographerGallery/thumbnails/6.jpg";
import thumbnail7 from "../../assets/photographerGallery/thumbnails/7.jpg";
import thumbnail8 from "../../assets/photographerGallery/thumbnails/8.jpg";
import thumbnail9 from "../../assets/photographerGallery/thumbnails/9.jpg";
import thumbnail10 from "../../assets/photographerGallery/thumbnails/10.jpg";
import thumbnail11 from "../../assets/photographerGallery/thumbnails/11.jpg";
import thumbnail12 from "../../assets/photographerGallery/thumbnails/12.jpg";
import thumbnail13 from "../../assets/photographerGallery/thumbnails/13.jpg";
import thumbnail14 from "../../assets/photographerGallery/thumbnails/14.jpg";
import thumbnail101 from "../../assets/photographerGallery/thumbnails/101.jpg";
import thumbnail102 from "../../assets/photographerGallery/thumbnails/102.jpg";
import thumbnail103 from "../../assets/photographerGallery/thumbnails/103.jpg";
import thumbnail104 from "../../assets/photographerGallery/thumbnails/104.jpg";
import thumbnail105 from "../../assets/photographerGallery/thumbnails/105.jpg";
import thumbnail106 from "../../assets/photographerGallery/thumbnails/106.jpg";
import thumbnail107 from "../../assets/photographerGallery/thumbnails/107.jpg";
import thumbnail108 from "../../assets/photographerGallery/thumbnails/108.jpg";
import thumbnail109 from "../../assets/photographerGallery/thumbnails/109.jpg";

import { useEffect } from "react";

const pic1Alt = "Köök ja söögilaud. Taustal mängumaja";
const pic2Alt =
  "Söögilaud ja serveerimislaud, mille kohal on vikerkaare värvides õhupallid";
const pic3Alt =
  "Siniste uste ja akendega valge kahekordne mängumaja. Maja kõrval kiik.";
const pic4Alt =
  "Siniste uste ja akendega valge kahekordne mängumaja. Maja kõrval lastele sõiduautod ja jooksuratas.";
const pic5Alt = "Sinise mängumaja sees on kohvimasin ja blender.";
const pic6Alt =
  "Sinise ja kollase mängumaja vahele saab laest alla lasta projektori ekraani.";
const pic7Alt =
  "Kollane mängumaja terrassiga. Trepp, et minna selle maja teisele korrusele.";
const pic8Alt = "Kollase mängumaja sees on kohvik";
const pic9Alt =
  "Vanakooli Volkswageni hipibuss lastele, mille katuselt saab lasta liugu pallimerre";
const pic10Alt =
  "Pallimeri ja selle kõrval laud, kuhu saab ehitada puidust raudtee.";
const pic11Alt = "Roosa mängumaja beebidele, mille kõrval on kiik tool.";
const pic12Alt =
  "Roheline mängumaja, kus on ühel pool toidupood ja teisel pool kostüümid lastele";
const pic13Alt =
  "Rallitool, rool ja pedaalid. Saab mängida autosõidu videomänge.";
const pic14Alt =
  "Mähkimisalus, koos mähkmete ja puhastuslappidega. Kõrval on WC uks.";
const pic101Alt = "Riidepuude peal erinevad kostüümid lastele";
const pic102Alt = "Kiiktool, kuhu saab kardina ette tõmmata ja imetada beebit";
const pic103Alt = "Mänguvõileib ühe mängumaja sees";
const pic104Alt = "Puust taignasegaja mängimiseks ja kunstlilled seinal.";
const pic105Alt = "Puidust röster ja kohvimasin mängimiseks";
const pic106Alt =
  "Puidust tort ja riiulil mängimiseks tassikoogikesed ja jäätisekokteilid. Mängukraanikauss ja -pliit";
const pic107Alt =
  "Mängumaja sees olev laud ja toolid. Laua peal on tassikoogid ja kunstlill";
const pic108Alt = "Kiikhobune, kiikelevant ja kiiklammas";
const pic109Alt = "Suur külmkapp, millel on üks uks lahti";

const Gallery = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  let images = [
    [pic1, thumbnail1, pic1Alt],
    [pic2, thumbnail2, pic2Alt],
    [pic3, thumbnail3, pic3Alt],
    [pic4, thumbnail4, pic4Alt],
    [pic5, thumbnail5, pic5Alt],
    [pic6, thumbnail6, pic6Alt],
    [pic7, thumbnail7, pic7Alt],
    [pic8, thumbnail8, pic8Alt],
    [pic9, thumbnail9, pic9Alt],
    [pic10, thumbnail10, pic10Alt],
    [pic11, thumbnail11, pic11Alt],
    [pic12, thumbnail12, pic12Alt],
    [pic13, thumbnail13, pic13Alt],
    [pic14, thumbnail14, pic14Alt],
    [pic101, thumbnail101, pic101Alt],
    [pic102, thumbnail102, pic102Alt],
    [pic103, thumbnail103, pic103Alt],
    [pic104, thumbnail104, pic104Alt],
    [pic105, thumbnail105, pic105Alt],
    [pic106, thumbnail106, pic106Alt],
    [pic107, thumbnail107, pic107Alt],
    [pic108, thumbnail108, pic108Alt],
    [pic109, thumbnail109, pic109Alt],
  ];
  return (
    <div className="container py-5">
      <h2>Galerii</h2>
      <div className="d-flex justify-content-center">
        <div className="max-70">
          <div>
            <ImageGallery
              items={images.map((img) => {
                return {
                  original: img[0],
                  thumbnail: img[1],
                  originalAlt: img[2],
                  thumbnailAlt: img[2],
                };
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
