import React, { useEffect } from "react";

const PriceList = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="green-wrapper">
      <div className="container py-5">
        <h2>Hinnakiri</h2>
        <div className="row pt-2 white-wrapper">
          <div className="col-lg-4 col-md-12">
            <h4>Ruumi broneering:</h4>
            <p>E-R 10:00-16:30 - 150 EUR/2h45min</p>
            <p>E-N 18:00-20:30 - 170 EUR/2h45min</p>
            <p>
              R 18:00-20:30 & <br />
              L-P 10:00-20:30 - 190 EUR/2h45min
            </p>
            <p className="pb-2">* Riigipühadel kehtib nädalavahetuse hind</p>
          </div>
          <div className="col-lg-5 col-md-12 px-3">
            <h4>Tingimused:</h4>
            <p>
              <b>
                Ruumi broneeringust on 2,5 tundi pidu ja 15 minutit enne pidu
                ettevalmistusteks.
              </b>
            </p>
            <p>Peo pikendamine: 30min - 30 eur (eelneval kokkuleppel)</p>
            <p>
              Palume kinni pidada peo lõpu kellaajast, et meil oleks võimalik
              korrastada stuudio järgmiste klientide jaoks. Kui kokkulepitud peo
              lõpu kellaajast minnakse üle, hakkavad jooksma lisaminutid (1
              minut = 2 eur).
            </p>
          </div>
          <div className="col-lg-3 col-md-12">
            <h4>Hinnad sisaldavad:</h4>
            <ul>
              <li>
                <p>15 min ettevalmistamise aega enne pidu.</p>
              </li>
              <li>
                <p>2,5 tundi pidu külalistega</p>
              </li>
              <li>
                <p>
                  Kohvimasina kasutust (Meelepärane piim tuleb endal kaasa
                  võtta)
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceList;
