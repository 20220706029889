import React, { Component } from "react";
import "../style.css";
import emailjs from "@emailjs/browser";

class BookingDetails extends Component {
  sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_ji6frok",
      "template_v6yywka",
      e.target,
      "vTK2OChvHp6n2ZUhA"
    );
    this.props.thankYouScreen();
  };
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }
  render() {
    const rent = this.props.sumTotal - 40;

    return (
      <div className="card">
        <div className="text-center user-data"></div>
        <div id="no-defects">
          <div className="card-body">
            <h4 className="card-title mb-5">Broneeringu ülevaade</h4>
            <h6 className="mb-3">
              Kuupäev: <b>{this.props.formatedDate}</b>
            </h6>
            <h6 className="mb-3">
              Kellaaeg: <b>{this.props.yTime}</b>
            </h6>
            <h6 className="mb-3">
              Summa: <b>{this.props.sumTotal}€</b>
            </h6>
            <h6 className="mb-3">
              Nimi: <b>{this.props.name}</b>
            </h6>
            <h6 className="mb-3">
              E-mail: <b>{this.props.email}</b>
            </h6>
            <h6 className="mb-3">
              Telefon: <b>{this.props.phone}</b>
            </h6>
            <h6 className="mb-3">
              Lisainfo: <b>{this.props.info}</b>
            </h6>
            <h6 className="mb-3">
              Pehmejäätisemasina rent:{" "}
              <b>{this.props.iceCream ? "Jah" : "Ei"}</b>
            </h6>
            <h6 className="mb-3">
              Koristusteenus: <b>{this.props.cleaning ? "Jah" : "Ei"}</b>
            </h6>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={this.sendEmail} id="myForm">
            <input
              type="hidden"
              className="form-control"
              name="date"
              defaultValue={this.props.formatedDate}
            />
            <input
              type="hidden"
              className="form-control"
              name="time"
              defaultValue={this.props.yTime}
            />
            <input
              type="hidden"
              className="form-control"
              name="sum"
              defaultValue={this.props.sumTotal}
            />
            <input
              type="hidden"
              className="form-control"
              name="rent"
              defaultValue={rent}
            />
            <input
              type="hidden"
              className="form-control"
              name="name"
              defaultValue={this.props.name}
            />
            <input
              type="hidden"
              className="form-control"
              name="email"
              defaultValue={this.props.email}
            />
            <input
              type="hidden"
              className="form-control"
              name="phone"
              defaultValue={this.props.phone}
            />
            <input
              type="hidden"
              className="form-control"
              name="info"
              defaultValue={this.props.info}
            />
            <input
              type="hidden"
              className="form-control"
              name="iceCream"
              defaultValue={this.props.iceCream ? "Jah" : "Ei"}
            />
            <input
              type="hidden"
              className="form-control"
              name="cleaning"
              defaultValue={this.props.cleaning ? "Jah" : "Ei"}
            />
          </form>
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-secondary btn-md "
              onClick={this.props.backToServices}
            >
              Muuda
            </button>
            <button
              type="submit"
              form="myForm"
              className="btn btn-lg purple-bg btn-block"
              //onClick={this.props.thankYouScreen}
            >
              Broneeri aeg
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingDetails;
