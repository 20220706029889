import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/footer-logo.png";
import "../style.css";
import { GrInstagram } from "react-icons/gr";
import { ImFacebook2 } from "react-icons/im";

const Footer = () => {
  return (
    <div>
      <div className="container">
        <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-5 pt-5 my-5 border-top">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <h5>Mängutoa info</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link
                  to="/tutvustus"
                  className="nav-link p-0 text-body-secondary"
                >
                  Tutvustus
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/galerii"
                  className="nav-link p-0 text-body-secondary"
                >
                  Galerii
                </Link>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="/kodukord"
                  className="nav-link p-0 text-body-secondary"
                >
                  Kodukord
                </a>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/hinnakiri"
                  className="nav-link p-0 text-body-secondary"
                >
                  Hinnakiri
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/broneerimine"
                  className="nav-link p-0 text-body-secondary"
                >
                  Broneerimine
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <h5>Lisateenused</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link
                  to="/pehmejaatis"
                  className="nav-link p-0 text-body-secondary"
                >
                  Pehme jäätis
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/kutsed" className="nav-link p-0 text-body-secondary">
                  Kutsed
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/kooker" className="nav-link p-0 text-body-secondary">
                  Kooker pannkoogid
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/peojuht"
                  className="nav-link p-0 text-body-secondary"
                >
                  Peojuht
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/magusameister"
                  className="nav-link p-0 text-body-secondary"
                >
                  Magusameister
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/naomaalingud"
                  className="nav-link p-0 text-body-secondary"
                >
                  Näomaalingud
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/ohupallid"
                  className="nav-link p-0 text-body-secondary"
                >
                  Õhupallid
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/fotograaf"
                  className="nav-link p-0 text-body-secondary"
                >
                  Fotograaf
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <h5>Kontakt</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <p>Telefon: +372 58892820</p>
                <p>E-mail: info@sweetheartstuudio.ee</p>
              </li>
              <li className="nav-item mb-3 d-flex">
                <a
                  href="https://www.facebook.com/sweetheartstuudio"
                  target="_blank"
                  className="nav-link p-0 text-body-secondary"
                >
                  <ImFacebook2 size={"2rem"} />
                </a>
                <a
                  href="https://www.instagram.com/sweetheartstuudio"
                  target="_blank"
                  className="nav-link p-0 text-body-secondary px-3"
                >
                  <GrInstagram size={"2rem"} />
                </a>
              </li>
              <li className="nav-item mb-2"></li>
              <li className="nav-item mb-2">
                <p>
                  Sweetheart OÜ <br />
                  reg nr 12773500
                </p>
                <p>
                  LHV SWIFT LHVBEE22 <br />
                  IBAN EE237700771009802473
                </p>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <h5>Aadress</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <p>Asume Mustamäe ja Kristiine piiril:</p>
              </li>
              <li className="nav-item mb-4">
                <p>
                  Kadaka tee 36, Tallinn <br />
                  II korrus
                </p>
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  className="d-flex justify-content-center mb-3 link-body-emphasis text-decoration-none"
                >
                  <img
                    className="logo-footer"
                    src={logo}
                    alt="Kits õhupallidega"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
