import React, { Component } from "react";
import "./style.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Main from "./Components/pages/Main";
import Book from "./Components/pages/Book";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/pages/Home";
import HouseRules from "./Components/pages/HouseRules";
import PriceList from "./Components/pages/PriceList";
import Introduction from "./Components/pages/Introduction";
import Footer from "./Components/Footer";
import Contact from "./Components/pages/Contact";
import Gallery from "./Components/pages/Gallery";
import Kooker from "./Components/pages/Kooker";
import PartyHost from "./Components/pages/PartyHost";
import CakeBoss from "./Components/pages/CakeBoss";
import Balloons from "./Components/pages/Balloons";
import Photographer from "./Components/pages/Photographer";
import Invites from "./Components/pages/Invites";
import IceCream from "./Components/pages/IceCream";
import FacePaintings from "./Components/pages/FacePaintings";
import { IoArrowUpOutline } from "react-icons/io5";

import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }
  scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <div className="pb-5 position-relative">
        <BrowserRouter>
          <Main />
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/tutvustus"} element={<Introduction />} />
            <Route path={"/galerii"} element={<Gallery />} />
            <Route path={"/kodukord"} element={<HouseRules />} />
            <Route path={"/hinnakiri"} element={<PriceList />} />
            <Route path={"/broneerimine"} element={<Book />} />
            <Route path={"/kontakt"} element={<Contact />} />
            <Route path={"/kooker"} element={<Kooker />} />
            <Route path={"/peojuht"} element={<PartyHost />} />
            <Route path={"/magusameister"} element={<CakeBoss />} />
            <Route path={"/ohupallid"} element={<Balloons />} />
            <Route path={"/naomaalingud"} element={<FacePaintings />} />
            <Route path={"/pehmejaatis"} element={<IceCream />} />
            <Route path={"/kutsed"} element={<Invites />} />
            <Route path={"/fotograaf"} element={<Photographer />} />
            <Route
              path={"/sweetheart-maengu-ja-peostuudio"}
              element={<Navigate to="/" />}
            />
          </Routes>
          <IoArrowUpOutline
            className="scroll-arrow"
            onClick={() => this.scrollTop()}
          />
          <Footer />
        </BrowserRouter>
        <SpeedInsights />
        <Analytics />
      </div>
    );
  }
}

//render(<App />, document.getElementById("root"));
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
