import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import logo from "../assets/Nimi-ohupalliga.jpg";

import "../style.css";

const Navigationbar = () => {
  const [expanded, setExpanded] = useState(false);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  return (
    <Navbar
      expanded={expanded}
      // fixed="top"
      expand="lg"
      className="navbar ms-auto ml-auto"
    >
      <Container>
        <Navbar.Brand as={Link} onClick={() => setExpanded(false)} to="/">
          <div className="d-flex align-items-center">
            <img className="logo" src={logo} alt="Kits õhupallidega" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              as={Link}
              onClick={() => setExpanded(false)}
              to="/tutvustus"
            >
              <span className="navi-link dancing-font-s">Tutvustus</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={() => setExpanded(false)}
              to="/galerii"
            >
              <span className="navi-link dancing-font-s">Galerii</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={() => setExpanded(false)}
              to="/kodukord"
            >
              <span className="navi-link dancing-font-s">Kodukord</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={() => setExpanded(false)}
              to="/hinnakiri"
            >
              <span className="navi-link dancing-font-s">Hinnakiri</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={() => setExpanded(false)}
              to="/broneerimine"
            >
              <span className="nav-bron dancing-font-s">Broneerimine</span>
            </Nav.Link>
            <NavDropdown
              className="navi-link dancing-font-s"
              title="Lisateenused"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                as={Link}
                onClick={() => setExpanded(false)}
                to="/pehmejaatis"
              >
                <span className="navi-link dancing-font-s">Pehme jäätis</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                onClick={() => setExpanded(false)}
                to="/kutsed"
              >
                <span className="navi-link dancing-font-s">Kutsed</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                onClick={() => setExpanded(false)}
                to="/kooker"
              >
                <span className="navi-link dancing-font-s">
                  Kooker pannkoogid
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                onClick={() => setExpanded(false)}
                to="/peojuht"
              >
                <span className="navi-link dancing-font-s">Peojuht</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                onClick={() => setExpanded(false)}
                to="/magusameister"
              >
                <span className="navi-link dancing-font-s">Magusameister</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                onClick={() => setExpanded(false)}
                to="/naomaalingud"
              >
                <span className="navi-link dancing-font-s">Näomaalingud</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                onClick={() => setExpanded(false)}
                to="/ohupallid"
              >
                <span className="navi-link dancing-font-s">Õhupallid</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                onClick={() => setExpanded(false)}
                to="/fotograaf"
              >
                <span className="navi-link dancing-font-s">Fotograaf</span>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              onClick={() => setExpanded(false)}
              to="/kontakt"
            >
              <span className="navi-link dancing-font-s">Kontakt</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
