import React, { Component } from "react";
import "../style.css";
import { Link } from "react-router-dom";

class SelectedList extends Component {
  render() {
    return (
      <form className="selectedlist" onSubmit={this.props.dtNext} id="preBook">
        <ul className="list-group">
          <li className="list-group-item list-group-item-action">
            Kuupäev:&nbsp;
            <b>{this.props.dateSelectedLi ? this.props.formatedDate : null}</b>
          </li>
          <li className="list-group-item list-group-item-action">
            Kellaaeg:&nbsp;
            <b>{this.props.yTime.length > 0 ? this.props.yTime : null}</b>
          </li>
          <li className="list-group-item list-group-item-action">
            Summa:&nbsp;
            <b>{this.props.sumTotal ? `${this.props.sumTotal}€` : null}</b>
          </li>
        </ul>
        <div className=" mt-3">
          <div className="mb-3">
            <label className="form-label" htmlFor="name">
              Nimi:
            </label>
            <input
              onChange={(e) => this.props.handleChange(e, "name")}
              value={this.props.name}
              type="text"
              className="form-control"
              id="name"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              E-mail:
            </label>
            <input
              onChange={(e) => this.props.handleChange(e, "email")}
              value={this.props.email}
              type="email"
              className="form-control"
              id="email"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="phone">
              Telefon:
            </label>
            <input
              onChange={(e) => this.props.handleChange(e, "phone")}
              value={this.props.phone}
              type="text"
              className="form-control"
              id="phone"
              required
            />
          </div>
          <div className="mb-3 pt-2">
            <label className="form-label" htmlFor="icecream">
              Pehmejäätisemasina rent{" "}
              <Link to="/pehmejaatis" className="black-link">
                (Loe lähemalt)
              </Link>
            </label>
            <div className="d-flex gap-3">
              <p>Soovin (+65€):</p>
              <input
                onChange={this.props.iceCreamCheckHandler}
                checked={this.props.iceCream}
                type="checkbox"
                className="form-check-input"
                id="icecream"
              />
            </div>
          </div>

          <div className="mb-3 pt-2">
            <label className="form-label" htmlFor="icecream">
              Koristusteenus (Teeme ise köögi- ja mänguala korda)
            </label>
            <div className="d-flex gap-3">
              <p>Soovin (+35€):</p>
              <input
                onChange={this.props.cleaningCheckHandler}
                checked={this.props.cleaning}
                type="checkbox"
                className="form-check-input"
                id="icecream"
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="info">
              Lisainfo:
            </label>
            <textarea
              onChange={(e) => this.props.handleChange(e, "info")}
              value={this.props.info}
              placeholder="Soovikorral lapse nimi ja kui vanaks ta saab, et lisada need peol bännerile"
              rows="4"
              className="form-control"
              id="info"
            />
          </div>
        </div>
        <button
          className="self-align-right btn purple-bg mt-3"
          disabled={!this.props.yTime}
          type="submit"
        >
          Edasi
        </button>
      </form>
    );
  }
}

export default SelectedList;
