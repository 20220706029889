import React, { useEffect } from "react";

import balloon3 from "../../assets/balloon3.webp";
import balloon4 from "../../assets/balloon4.webp";
import balloon5 from "../../assets/balloon5.webp";

const Balloons = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="container py-5">
      <h2>Debash Deko Õhupallidest Dekoratsioonid</h2>
      <div className="row white-wrapper">
        <div className="col-lg-6 col-md-12 ">
          <p>
            Me teame kui palju lastele meeldivad õhupallid ja vanematele lahedad
            pildid lapse sünnipäevast, mida ka aastate möödudes koos vaadata.
          </p>
          <p>
            Pakume õhupallidest eristuvaid ja isikupäraseid dekoratsioone -
            väikesest kompositsioonist suure fotoseinani. Õhupallidekoratsioonid
            valmivad eritellimusel, võttes arvesse teie ja lapse soove. Kas
            lapsel on lemmikvärv, multifilmi tegelane või on teil endal hoopis
            muu põnev idee? Olgu Käpapatrull, Elsa, safari või meremaailm - kõik
            on võimalik
          </p>
          <p>
            *Õhupallid, mida oma töödes kasutan on looduslikust lateksist ja
            heeliumivabad.
          </p>
          <p className="pt-4">
            Email: <span>debash.deko@gmail.com</span>
          </p>
          <p>
            Instagram:{" "}
            <a
              className="black-link"
              href="https://instagram.com/debash_deko"
              target="_blank"
            >
              https://instagram.com/debash_deko
            </a>
          </p>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic mb-4"
            src={balloon5}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>

        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic mb-4"
            src={balloon3}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic"
            src={balloon4}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
      </div>
    </div>
  );
};

export default Balloons;
