import React, { useEffect } from "react";
import logo from "../../assets/kits.webp";
import kook from "../../assets/photographerGallery/1.jpg";
import soogiala1 from "../../assets/photographerGallery/3.jpg";
import soogiala2 from "../../assets/photographerGallery/7.jpg";
import ralli from "../../assets/photographerGallery/13.jpg";
import roosa from "../../assets/photographerGallery/11.jpg";
import linn from "../../assets/photographerGallery/9.jpg";
import projektor from "../../assets/photographerGallery/6.jpg";

const Introduction = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div>
      <div className="container py-5">
        <h2>Mängutuba</h2>
        <div className="row pb-3 pt-2">
          <div className="col">
            <p>
              Sweetheart mängu- ja peostuudio on armas hubane kohake, mis on
              südamega loodud Sinu kõige kallimate tähtsate sündmuste
              tähistamiseks. Sweetheart on detailideni läbi mõeldud, et nii suur
              kui väike sõber saaks nautida meeldejäävat pidu. Ootame oma
              stuudiosse külalisi, kes hindavad meie südamega loodud pesa ning
              soovivad seda hoida sama hoolega.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 d-flex align-items-center">
            <img className="intr-pic" src={logo} alt="kits õhupalliga" />
          </div>
        </div>
        <div className="row py-5 px-4 mb-4 beige-wrapper-intr">
          <div className="col-lg-4 col-md-12">
            <h5>Palju meile külalisi mahub?</h5>
            <p>
              Mängutuba on mõeldud pigem rahulikemateks rollimängudeks ja
              mõnusaks olemiseks. Suuri ronimis-ja möllamisatraktsioone meil ei
              ole. Stuudio on kaunistatud paljude nipsasjakestega. Parim on siin
              üritust pidada 35-40 inimesega (lapsed kaasa arvatud). Sel juhul
              jagub kõigile mõnusalt ruumi ja õhku.
            </p>
          </div>

          <div className="col-lg-4 col-md-12">
            <h5>Kes on põhiline sihtgrupp?</h5>
            <p>
              155m2 suurune mängutuba on loodud koostöös meie sihtgrupi
              esindajate - laste endiga. Seega on mänguala kokku pandud
              hoolikalt mõeldes ja laste lennukaid ideid silmas pidades. Meie
              mängustuudios leiab põnevat tegevust nii pisike kui ka veidi
              suurem laps. Sweetheart on eelkõige rollimängudel põhinev
              mängustuudio ja meie peamiseks sihtgrupiks on 0-7a lapsed, kuid
              tegevust leiavad ka 10-aastased ja vanemad.
            </p>
          </div>
          <div className="col-lg-4 col-md-12">
            <h5>Mida pakume kõige pisematele?</h5>
            <p>
              Mängutuba on erilise hoolega mõelnud kõige pisemate mugavuse
              peale. Meil on arendavate mänguasjadega spetsiaalne beebiala.
              Tualetist leiab mähkimislaua ja isegi mähkmed, kui peaks juhtuma,
              et mõnel pisikesel külalisel vajalik varustus koju peaks ununema.
            </p>
          </div>
        </div>
        <h4 className="pt-5">Meie stuudio jaguneb neljaks ruumiks:</h4>
        <h5 className="pb-4">"Kodu", "Rallituba", "Roosa tuba" ja "Linn"</h5>
        <div className="row pb-3">
          <h3 className="pb-2">Kodu</h3>
          <div className="col-lg-6 col-md-12">
            <p>
              <b>Mängutuppa sisenedes</b> soovime, et tunneksid end nagu kodus.
              Selleks saab garderoobi asetada oma üleriided ja välisjalanõud.
            </p>
            <p>
              <b>Köögipoolel on</b> olemas kõik vajalik! Meil on suur külmik,
              ahi, mikrolaineahi, kohvimasin, veekeedukann. Sinu mugavus ja
              keskkonnasõbralik käitumine on meile väga olulised, seega oleme
              varustanud köögi kõikide vajalike söögiriistadega. Sa ei pea peole
              ühekordseid nõusid kaasa võtma, nii säästad loodust, raha ja aega.
              Meie kappidest leiad taldrikud, tassid, klaasid, lusikad, noad,
              kahvlid, tordilabida, tordialuse, morsikannud, serveerimisalused,
              kausid jms 30 inimesele. Ka kohvi, tee, suhkru peole kaasa võtmine
              pole vajalik, meie köögis saad mugavalt kasutada veekeedukannu ja
              kohvimasinat. Võimalik on teha tavalist kohvi või cappucinot.
              Piim/kohvikoor on vajalik kliendil ise kaasa võtta, et nautida
              head kohvi.
            </p>
            <p>
              <b>Söögitoas on</b> kaks lauda, mis mahutavad korraga istuma kuni
              18 inimest. Lisaks on üks serveerimislaud, kuhu saab katta
              soolase/magusalaua. Samuti on meil lastelaud, mis mahutab 6-10
              last korraga istuma.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center mb-3">
            <img className="intr-pic " src={kook} alt="köök tehnikaga" />
          </div>
        </div>
        <div className="row pb-5 mb-3">
          <h5>Kodu toa mängualal on kaks majakest:</h5>
          <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
            <ul className="py-5 px-4 green-wrapper-intr">
              <li>
                <p>
                  2-korruseline rõduga kodumaja koos kiigealaga. Kodumajas on
                  olemas kõik, mis ühes korralikus kodus ikka olemas on.
                  Kodumajas saab toitu valmistada armsas köögis, pesu pesta,
                  kuivatada seda pesurestil ja hiljem sirgeks triikida. Kodumaja
                  teisel korrusel on lastetuba koos voodi ja muu vajalikuga.
                  Samuti on olemas arstikohver, kui on vaja väikeseid
                  abivajajaid ravida. Kodumaja kõrval saab nautida
                  kiikumisrõõme.
                </p>
              </li>
              <li>
                <p>
                  2-korruseline kohvik/raamatukogu. Alumisel korrusel asuvas
                  kohvikus saab lisaks koogikestele, kohvile ja teele mekkida ka
                  jäätisevalikut. Magusaid suupisteid on võimalik nautida
                  istudes sõpradega terrassil. Teisel korrusel asub
                  puhke-lugemisala, kust leiab lugemist ja lauamänge nii suur
                  kui väike külaline.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center intr-50-pics">
              <img
                className="intr-pic max-50 m-2"
                src={soogiala1}
                alt="kahekorde mängumaja, sinise ukse ja siniste aknaraamidega"
              />

              <img
                className="intr-pic max-50 m-2"
                src={soogiala2}
                alt="kahekordne kollane mängumaja väikese terrassiga"
              />
            </div>
          </div>
        </div>

        <div className="row pb-5 mb-3">
          <h3 className="pb-2">Rallituba</h3>
          <div className="col-lg-6 col-md-12 mb-2 d-flex justify-content-center align-items-center">
            <img className="intr-pic" src={ralli} alt="roosa mängumaja" />
          </div>

          <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
            <div className="py-5 px-4 beige-wrapper-intr">
              <p>
                See tuba pakub tegevust kõigile autohuvilistele. Poovile saab
                ennast panna nii ralli kui ka ringrajasõidus. <br />
                Külaliste kasutuses on 2 mängu: Dirt 5 ja Forza Motorsport 7.
              </p>
              <p>
                Võimalik on teha lihtsalt lõbusõite või korraldada väikene
                võistlus kiireima ralliässa välja selgitamiseks.
              </p>
              <p>Mängimine on sobilik alates 7. eluaastast.</p>
            </div>
          </div>
        </div>

        <div className="row pb-5 mb-3">
          <h3 className="pb-2">Roosa tuba</h3>
          <div className="col-lg-6 col-md-12 mb-2 d-flex align-items-center justify-content-center">
            <div className="py-5 px-4 pink-wrapper-intr">
              <p>
                Selles toas leiavad tegevust kõige pisemad. Kõige pisematele on
                meil olemas väike majake, kus saab avastada motoorikaseina ning
                arendavate mänguasjadega mängida. Suurel beebialal on veel
                beebitool, beebi mängukaar ning kiiktool, kus on mõnus beebit
                kiigutada.{" "}
              </p>
              <p>
                Ilusalong, kus saab juuksed kauniks, küüned korda, mängumeiki
                teha ning proovida erinevaid kostüüme. Kostüüminurgas saavad
                pidulised end kehastada kauniteks printsessideks või hoopis
                näiteks tuletõrjujaks, superkangelaseks.
              </p>
              <p>
                Toidupood, kust osteldes saab kõike head ja paremat laduda kas
                ostukärusse või sooritada ost kioskiaknast.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <img className="intr-pic " src={roosa} alt="roosa mängumaja" />
          </div>
        </div>

        <div className="row pb-5 mb-4">
          <h3 className="pb-2">Linn</h3>
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center mb-2">
            <img
              className="intr-pic"
              src={linn}
              alt="puust volkswageni buss ja liumägi pallimerre"
            />
          </div>
          <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
            <div className="py-5 px-4 blue-wrapper-intr">
              <p>
                Sellest toast leiab retrobussi, millega on põnev nii linnas kui
                maal ringi reisida. Külalised saavad istuda bussi rooli,
                vajutada pedaale ja suunatulesid. Bussi katuselt on võimalik
                alla lasta liumäest toredasse pallimerre. Toas on farmi esine,
                kus saab hobuse ja lambaga kiikuda.{" "}
              </p>
              <p>
                Lisaks on toas legolaud, puidust rongikomplekt, tipitelk, mille
                juures on mitmeid põnevaid pille, millega musitseerida. Samuti
                on meil kvaliteetsed baghera vintage tõuke- ja pedaalautod,
                jooksurattad, ronimiskolmnurk koos ronimislauaga, Kiiga-Kaaga
                poolt valmistatud käsitöö kiik, nukuvankrid, puidust klotsid ja
                veel palju põnevat.
              </p>
            </div>
          </div>
        </div>

        <div className="row pb-5 mb-3">
          <h3 className="pb-2">Projektor</h3>
          <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center mb-2">
            <div className="py-5 px-4 pink-wrapper-intr">
              <p>
                Prjoketor on ühendatud mängukonsooli ja Google TV-ga. Lisaks on
                võimalik kasutada HDMI juhet. Ekraan on diagonaaliga 100".
              </p>
              <p>
                Saab lõbutseda Just Dance mänguga, kus tuleb tantsida
                võimalikult täpselt, et teenida suurem skoor. Korraga saab
                osaleda 2-6 tantsijat.
              </p>
              <p>
                Võimalus enda seadmest (nutitelefon või sülearvuti) vaadata
                filmi suurel ekraanil. Mugavaks istumiseks on suur diivan,
                tugitool, kott-toolid ja pehmed padjad.
              </p>
              <p>Projektori pildi kvaliteet on parem, kui siin tundub.</p>
              <p>
                Projektori kasutamise soovist palume teada anda broneeringut
                tehes, et saaksime seadmed enne pidu valmis panna.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center mb-2">
            <img
              className="intr-pic"
              src={projektor}
              alt="Projektoriekraan, millel on Just Dance mäng"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
