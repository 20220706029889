import React, { Component } from "react";
import ServicesData from "../ServicesData.json";
import SelectedList from "../SelectedList";
import DateTime from "../Date_time";
import BookingDetails from "../BookingDetails";
import ThankYou from "../ThankYou";

import "../../style.css";

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      title: "aeg",
      data: ServicesData,
      newDate: new Date(),
      selectedDate: "",
      weekDay: -1,
      dateSelected: false,
      timeSelected: false,
      serviceStage: true,
      BookingDetails: false,
      thankYouScreen: false,

      //Form
      form: {
        date: "",
        time: "",
        name: "",
        email: "",
        phone: "",
        info: "",
        iceCream: false,
        cleaning: false,
      },

      sumTotal: 0,

      datetimeStep: true,

      //Selected Time
      selectedLi: -1,
    };
  }

  //Handle form change
  handleChange = (e, input) => {
    e.preventDefault();
    //localStorage.setItem(input, e.target.value);
    this.setState({
      form: {
        ...this.state.form,
        [input]: e.target.value,
      },
    });
  };

  iceCreamCheckHandler = (e) => {
    //localStorage.setItem(input, e.target.value);
    this.state.form.iceCream
      ? this.setState({
          sumTotal: this.state.sumTotal - 65,
          form: {
            ...this.state.form,
            iceCream: !this.state.form.iceCream,
          },
        })
      : this.setState({
          sumTotal: this.state.sumTotal + 65,
          form: {
            ...this.state.form,
            iceCream: !this.state.form.iceCream,
          },
        });
  };

  cleaningCheckHandler = (e) => {
    //localStorage.setItem(input, e.target.value);
    this.state.form.cleaning
      ? this.setState({
          sumTotal: this.state.sumTotal - 35,
          form: {
            ...this.state.form,
            cleaning: !this.state.form.cleaning,
          },
        })
      : this.setState({
          sumTotal: this.state.sumTotal + 35,
          form: {
            ...this.state.form,
            cleaning: !this.state.form.cleaning,
          },
        });
  };

  //Calculate Sum
  calcSum(weekDay, time) {
    if (weekDay === 6 || weekDay === 7) {
      return 190;
    }
    if (weekDay === 5 && time === "18:00-20:30") {
      return 190;
    }
    if (weekDay > 0 && weekDay < 5 && time === "18:00-20:30") {
      return 170;
    }
    return 150;
  }

  // List bg
  setSelectedLi = (id) => {
    this.setState({
      selectedLi: id,
    });
  };

  //Date selection
  DateonChange = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const newDate = [day, month, year];

    const isHoliday = () => {
      return ServicesData.holidays.some(
        (holiday) => holiday.date === `${day}.${month + 1}.${year}`
      )
        ? true
        : false;
    };

    const weekDay = isHoliday() ? 0 : date.getDay();

    this.setState({
      selectedDate: date,
      selectedLi: -1,
      weekDay: weekDay === 0 ? 7 : weekDay,
      sumTotal: 0,
      dateSelected: true,
      form: {
        ...this.state.form,
        date: newDate,
        time: "",
        iceCream: false,
        cleaning: false,
      },
    });
  };

  //Time selection
  selectedTime = (i) => {
    const sumTotal = this.calcSum(this.state.weekDay, i);
    this.setState({
      sumTotal: sumTotal,
      timeSelected: true,
      form: {
        ...this.state.form,
        time: i,
        iceCream: false,
        cleaning: false,
      },
    });
  };

  formatDate = (yDate) => {
    const months = [
      "jaanuar",
      "veebruar",
      "märts",
      "aprill",
      "mai",
      "juuni",
      "juuli",
      "august",
      "september",
      "oktoober",
      "november",
      "detsember",
    ];
    //const splittedDate = yDate.split("/");
    // if (date[0].length < 2) date[0] = "0" + date[0];
    // if (date[1].length < 2) date[1] = "0" + date[1];

    return `${yDate[0]}. ${months[yDate[1]]} ${yDate[2]}`;
  };

  dtNext = (e) => {
    e.preventDefault();
    this.setState({
      stylishStep: false,
      serviceStage: false,
      BookingDetails: true,
    });
  };

  backToServices = () => {
    this.setState({
      title: "service",
      BookingDetails: false,
      serviceStage: true,
      serviceStep: false,
      datetimeStep: true,
    });
  };

  thankYouScreen = () => {
    this.setState({
      thankYouScreen: true,
      BookingDetails: false,
    });
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }

  render() {
    return (
      <div className="blue-wrapper">
        <div className="container d-flex flex-column align-items-center py-5">
          {this.state.serviceStage ? (
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex flex-column ">
                <h2>Broneerimine</h2>
                <div className="white-wrapper">
                  <div className="row px-4">
                    <p className="col-sm-6 col-xs-12">
                      Sündmuse broneerimiseks valige kalendrist välja sobiv
                      kuupäev, kellaaeg ning saatke meile kiri kasutades
                      broneerimisvormi.
                    </p>
                    <p className="col-sm-6 col-xs-12">
                      Erisoovide puhul võtke julgelt ühendust telefoni või
                      e-posti teel.
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center pb-4 booking_block ">
                  <div className="col-md-6 mt-4 text-center left-block">
                    <h3 className="mb-4">
                      Valige sobiv kuupäev ja seejärel kellaaeg
                    </h3>
                    <p>
                      Lühema etteteatamisega, kui 48h, palume võtta ühendust
                      telefoni või e-posti teel
                    </p>
                    <div className="d-flex justify-content-center mb-3 ">
                      {this.state.datetimeStep ? (
                        <DateTime
                          onChange={this.DateonChange}
                          date={this.state.date}
                          selectedDate={this.state.selectedDate}
                          timings={this.state.data.timings}
                          dateSelectedLi={this.state.dateSelected}
                          selectedTime={this.selectedTime}
                          dtNext={this.dtNext}
                          yDate={this.state.form.date}
                          yTime={this.state.form.time}
                          weekDay={this.state.weekDay}
                          calcSum={this.calcSum}
                          selectedLi={this.state.selectedLi}
                          setSelectedLi={this.setSelectedLi}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 pb-3 mt-4 right-block">
                    <h3 className="text-center mb-4">Broneeringu kokkuvõte</h3>
                    <div className="d-flex justify-content-center">
                      <SelectedList
                        name={this.state.form.name}
                        email={this.state.form.email}
                        phone={this.state.form.phone}
                        info={this.state.form.info}
                        iceCream={this.state.form.iceCream}
                        cleaning={this.state.form.cleaning}
                        dtNext={this.dtNext}
                        formatedDate={this.formatDate(this.state.form.date)}
                        yTime={this.state.form.time}
                        dateSelectedLi={this.state.dateSelected}
                        delete={this.delete}
                        sumTotal={this.state.sumTotal}
                        handleChange={this.handleChange}
                        iceCreamCheckHandler={this.iceCreamCheckHandler}
                        cleaningCheckHandler={this.cleaningCheckHandler}
                      />
                    </div>
                  </div>
                </div>
                <ul className="white-wrapper pt-5 px-5">
                  <li>
                    <p>
                      Pärast broneerimisvormi täitmist saadetakse Teile
                      kinnituskiri koos edasiste juhistega.
                    </p>
                  </li>
                  <li>
                    <p>
                      Pärast broneeringukinnituse ja arve saamist palume tasuda
                      broneerimistasu 40 eur 24h jooksul meie arveldusarvele.
                    </p>
                  </li>
                  <li>
                    <p>
                      Arvel on broneeringutasu 40 eur, mis ei kuulu
                      tagastamisele, kui pidu jääb ära Stuudiost mitteolenevatel
                      põhjustel.
                    </p>
                  </li>
                  <li>
                    <p>
                      Broneerimistasu arvestatakse sündmuse kogumaksumusest
                      maha. Ülejäänud osa tasumine toimub arve alusel ülekandega
                      hiljemalt sündmuse päevaks. Tasuda saab ka sularahas
                      sündmuse päeval.
                    </p>
                  </li>
                  <li>
                    <p>
                      Broneerimistasu ei ole tagastatav. Juhul, kui sündmus
                      peaks kliendist tulenevalt ära jääma, tuleb sellest
                      vähemalt ööpäev enne sündmuse algust meile teada anda tel
                      nr +372 58892820. Sellisel juhul leiame Teie sündmusele
                      uue aja, mida saate kasutada ühe aasta jooksul.
                      Broneeringut saab muuta üks kord.
                    </p>
                  </li>
                  <li>
                    <p>
                      NB! Palume broneeringutasu maksta õigeaegselt, vastasel
                      juhul vabastame kuupäeva uutele soovijatele!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
          {this.state.BookingDetails ? (
            <div>
              <div className="row justify-content-center">
                <div className="col">
                  <BookingDetails
                    name={this.state.form.name}
                    email={this.state.form.email}
                    phone={this.state.form.phone}
                    info={this.state.form.info}
                    iceCream={this.state.form.iceCream}
                    cleaning={this.state.form.cleaning}
                    formatedDate={this.formatDate(this.state.form.date)}
                    yTime={this.state.form.time}
                    sumTotal={this.state.sumTotal}
                    backToServices={this.backToServices}
                    thankYouScreen={this.thankYouScreen}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {this.state.thankYouScreen ? <ThankYou /> : null}
        </div>
      </div>
    );
  }
}

export default Wrapper;
