import React, { useEffect } from "react";
import uula1 from "../../assets/uula1.jpg";
import uula2 from "../../assets/uula2.jpg";
import uula3 from "../../assets/uula3.jpg";

const FacePaintings = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="container py-5">
      <h2>Näomaalingud</h2>
      <div className="row white-wrapper">
        <div className="col-lg-6 col-md-12 pb-4">
          <p>
            Näomaalija Uula on suure praktikaga professionaal, kes teeb oma tööd
            kire ja armastusega. Ta on ainukesena Eestis lõpetanud
            rahvusvahelise näomaalijate kooli{" "}
            <b>International Face Painting School</b> kõige kõrgema taseme.
          </p>
          <p>
            Uula eesmärgiks on aidata kaasa Teie unistuste täitumisele. Ta
            soovib läbi oma tööde tuua naeratuse Teie näole ning luua mälestusi,
            mis kestavad kogu elu.
          </p>
          <p>
            Ta võib Teist teha kauni printsessi, hirmsa draakoni või armsa kiisu
            - imed sünnivad vaid loetud minutitega.
          </p>
          <p>
            Iseenesestmõistetavalt on ka kõik Uula töövahendid alates värvidest
            ja pintslitest, lõpetades Swarovski kristallidega, väga kvaliteetsed
            ja lastesõbralikud.
          </p>
          <p className="pt-3">Email: ursula.otsing@gmail.com</p>
          <p>
            Instagram:{" "}
            <a
              className="black-link"
              href="https://www.instagram.com/uulanaomaalingud/"
              target="_blank"
            >
              https://www.instagram.com/uulanaomaalingud/
            </a>
          </p>
          <p className="pt-3 fw-bold">Hinnakiri:</p>
          <p>1h / 70€</p>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic mb-4"
            src={uula1}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>

        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic mb-4"
            src={uula2}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic"
            src={uula3}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
      </div>
    </div>
  );
};

export default FacePaintings;
