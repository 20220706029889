import React, { Component } from "react";
import Calendar from "react-calendar";
import "../style.css";
import ServiceData from "./ServicesData.json";

class DateTime extends Component {
  selectedTime(i) {
    this.props.selectedTime(i);
  }

  bookedTimes = ServiceData.bookings;
  waiting = ServiceData.waiting;

  bookedTime = (el) => {
    const currentDate = `${this.props.yDate[0]}.${this.props.yDate[1] + 1}.${
      this.props.yDate[2]
    }`;
    if (
      this.bookedTimes.some(
        (obj) => obj.time === el && obj.date === currentDate
      )
    )
      return true;
  };

  waitingTransfer = (el) => {
    const currentDate = `${this.props.yDate[0]}.${this.props.yDate[1] + 1}.${
      this.props.yDate[2]
    }`;
    if (this.waiting.some((obj) => obj.time === el && obj.date === currentDate))
      return true;
  };

  render() {
    return (
      <div>
        <div className="d-flex justify-content-center pb-3 ">
          <Calendar
            onChange={this.props.onChange}
            value={
              this.props.selectedDate === ""
                ? this.props.date
                : new Date(this.props.selectedDate)
            }
            minDate={new Date(new Date().getTime() + 2 * 86400000)}
            locale="et-EE"
          />
        </div>

        {this.props.dateSelectedLi ? (
          <div className="time-list">
            <ul className="mt-4">
              <p>Valige kellaaeg:</p>
              {this.props.timings.map((el, i) => {
                return (
                  <li
                    className={` mb-2
                    ${this.props.selectedLi === i ? "chosen" : ""}
                    ${this.waitingTransfer(el) ? "waiting" : ""}
                    ${this.bookedTime(el) ? "booked" : ""}
                    ${
                      !this.bookedTime(el) && !this.waitingTransfer(el)
                        ? "vacant pointer"
                        : ""
                    }
                    `}
                    key={i}
                    onClick={() => {
                      if (this.waitingTransfer(el)) return false;
                      if (this.bookedTime(el)) return false;
                      this.selectedTime(el);
                      this.props.setSelectedLi(i);
                    }}
                  >
                    <div className="px-5 py-2">
                      Kell:&nbsp;<b>{el}</b> <br />
                      Hind:&nbsp;
                      <b>{this.props.calcSum(this.props.weekDay, el)}€</b>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}

        <div className="pt-4 d-flex align-items-center">
          <div className="color-box available"></div>
          <div className="px-2">Saadaval</div>
        </div>
        <div className="pt-3 d-flex align-items-center">
          <div className="color-box waiting"></div>
          <div className="px-2">Ootab kinnitust</div>
        </div>
        <div className="pt-3 d-flex align-items-center">
          <div className="color-box booked"></div>
          <div className="px-2">Broneering kinnitatud</div>
        </div>
      </div>
    );
  }
}

export default DateTime;
