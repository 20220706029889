import React, { useEffect } from "react";
import { saveAs } from "file-saver";

import sinineDocx from "../../assets/sinised-kutsed.docx";
import sininePdf from "../../assets/sinised-kutsed.pdf";
import sinineDocxRu from "../../assets/sinised-kutsed-RU.docx";
import sininePdfRu from "../../assets/sinised-kutsed-RU.pdf";

import roosaDocx from "../../assets/roosad-kutsed.docx";
import roosaPdf from "../../assets/roosad-kutsed.pdf";
import roosaDocxRu from "../../assets/roosad-kutsed-RU.docx";
import roosaPdfRu from "../../assets/roosad-kutsed-RU.pdf";

import sinineKutse from "../../assets/kutse-sinine.jpg";
import roosaKutse from "../../assets/kutse-roosa.jpg";

const Invites = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const onDownload = (file) => {
    saveAs(file, file);
  };

  return (
    <div className="container py-5">
      <h2>Kutsed</h2>
      <div className="row white-wrapper">
        <p>Siit on võimalik alla laadida kutsed eesti ja vene keeles.</p>
        <div className="col-lg-6 col-md-12 mb-5">
          <img
            className="max-100 pb-4"
            src={sinineKutse}
            alt="sinine sünnipäeva kutse"
          />
          <div className="row ">
            <div className="col">
              <h6 className="mb-3">Eesti keeles</h6>
              <p className="black-link" onClick={() => onDownload(sinineDocx)}>
                Sinine kutse docx
              </p>
              <p className="black-link" onClick={() => onDownload(sininePdf)}>
                Sinine kutse pdf
              </p>
            </div>
            <div className="col">
              <h6 className="mb-3">Vene keeles</h6>
              <p
                className="black-link"
                onClick={() => onDownload(sinineDocxRu)}
              >
                Sinine kutse docx
              </p>
              <p className="black-link" onClick={() => onDownload(sininePdfRu)}>
                Sinine kutse pdf
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 pb-4"
            src={roosaKutse}
            alt="sinine sünnipäeva kutse"
          />
          <div className="row">
            <div className="col">
              <h6 className="mb-3">Eesti keeles</h6>
              <p className="black-link" onClick={() => onDownload(roosaDocx)}>
                Roosa kutse docx
              </p>
              <p className="black-link" onClick={() => onDownload(roosaPdf)}>
                Roosa kutse pdf
              </p>
            </div>
            <div className="col">
              <h6 className="mb-3">Vene keeles</h6>
              <p className="black-link" onClick={() => onDownload(roosaDocxRu)}>
                Roosa kutse docx
              </p>
              <p className="black-link" onClick={() => onDownload(roosaPdfRu)}>
                Roosa kutse pdf
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invites;
