import React, { useEffect } from "react";
import ModalImage from "react-modal-image";
import peojuht1 from "../../assets/peojuht1.webp";
import peojuht2 from "../../assets/peojuht2.webp";
import peojuht3 from "../../assets/peojuht3.webp";
import peojuht4 from "../../assets/peojuht4.webp";
import peojuht5 from "../../assets/peojuht5.webp";
import peojuht6 from "../../assets/peojuht6.webp";

const PartyHost = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="container py-5">
      <h2>Peojuht</h2>
      <div className="row white-wrapper">
        <div className="col-lg-6 col-md-12 ">
          <p>
            <b>Printsessi Pesa peojuhid</b> tulevad kohale sünnipäevalapse
            lemmik printsessina ning muudavad peo meeldejäävaks, sisustades
            selle vahvate tegevustega.
          </p>
          <p>
            Peo korraldamisel võtame arvesse laste vanust ning sünnipäevalapse
            huvisid ning sisustame peo vastavalt kas erinevate mängude,
            laulmise, jutuvestmise, tantsimise või mõne muu kokkulepitud
            tegevusega. Igale peole on võimalik valida ka üks lisategevus:
            peotemaatikaga sobiv meisterdamine või dermatoloogiliselt testitud
            tatoveeringud.
          </p>
          <p>
            Loomulikult jääb aega pildistamiseks ning selleks, et lapsed saaks
            lõpuks kõik tähtsad küsimused oma lemmik printsessilt küsitud.
          </p>
          <p className="pt-4 fw-bold">Kontaktandmed:</p>
          <p>Telefon: 5524832</p>
          <p>
            E-mail: <span className="">printsessipesa@gmail.com</span>
          </p>

          <p>
            Facebook:{" "}
            <a
              className="black-link"
              href="https://www.facebook.com/printsessi.pesa"
              target="_blank"
            >
              https://www.facebook.com/printsessi.pesa
            </a>
          </p>
          <p className="pt-4 fw-bold">Hinnakiri:</p>
          <p>1h külastus 120€</p>
          <p>1.5h külastus 160€</p>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <div className="row pt-2">
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="gallery-image rounded">
                <ModalImage
                  className="w-100 shadow-1-strong"
                  small={peojuht1}
                  large={peojuht1}
                  alt="Elsa kostüümiga naine raja peal rohelises metsas"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="gallery-image rounded">
                <ModalImage
                  className="w-100 shadow-1-strong"
                  small={peojuht2}
                  large={peojuht2}
                  alt="Anna kostüümiga naine pargis"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="gallery-image rounded">
                <ModalImage
                  className="w-100 shadow-1-strong"
                  small={peojuht3}
                  large={peojuht3}
                  alt="Talvise kostüümiga naine"
                />
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="gallery-image rounded">
                <ModalImage
                  className="w-100 shadow-1-strong"
                  small={peojuht4}
                  large={peojuht4}
                  alt="Valge kleidiga naine"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="gallery-image rounded">
                <ModalImage
                  className="w-100 shadow-1-strong"
                  small={peojuht5}
                  large={peojuht5}
                  alt="Lilla printsessi kostüümiga naine jõulukuuse ees"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="gallery-image rounded">
                <ModalImage
                  className="w-100 shadow-1-strong"
                  small={peojuht6}
                  large={peojuht6}
                  alt="Anna kostüümiga naine ja 4 last tema ümber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartyHost;
