import React, { useEffect } from "react";

import cakeboss1 from "../../assets/cakeboss1.webp";
import cakeboss2 from "../../assets/cakeboss2.webp";
import cakeboss3 from "../../assets/cakeboss3.webp";

const CakeBoss = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="container py-5">
      <h2>Margery Cakeboss</h2>
      <div className="row white-wrapper">
        <div className="col-lg-6 col-md-12 ">
          <p>
            Hei! Mina olen Margery ja minu tordihobist on saanud tänaseks
            igapäeva töö, töö mida ma väga armastan. Viimased 2-3 aastat olen
            spetsialiseerunud lastetortidele. Olen läbinud väga mitmeid
            erinevaid baas- ja täiendkoolitusi. Armastan teha erinevate
            tegelaskujudega uhkeid fantaasiarikkaid ja temaatilisi torte.
          </p>
          <p>
            Torte valmistan koduköögis ja vastavalt raskusastmele jõuan teha
            nädalas keskmiselt 4-5 uhket torti, minu valikus leidub mitut
            erinevat sisu. Tordi välimuse osas saan Sind oma ideedega aidata,
            mõnikord piisab juba lemmik tegelaskujust ja sealt edasi loon
            kompositsiooni. Minu põhiliseks sihtgrupiks on kujunenud pisikesed
            lapsed, need kõige siiramate emotsioonidega silmaterad.
          </p>

          <p>
            Olen meelsasti valmis Sinu lapse sünnipäeva meeldejäävaks ja
            eriliseks muutma!
          </p>
          <p className="pt-4">
            Email: <span>margerykihho5@gmail.com</span>
          </p>
          <p>
            Instagram:{" "}
            <a
              className="black-link"
              href="https://instagram.com/cakeboss_bakeithappen"
              target="_blank"
            >
              https://instagram.com/cakeboss_bakeithappen
            </a>
          </p>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic mb-4"
            src={cakeboss1}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>

        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic mb-4"
            src={cakeboss2}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic"
            src={cakeboss3}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
      </div>
    </div>
  );
};

export default CakeBoss;
