import React, { Component } from "react";
import "../style.css";

class ThankYou extends Component {
  render() {
    return (
      <div className="row justify-content-center">
        <div className="col">
          <h5>Kiri saadetud.</h5>
          <p>Juhised broneeringu kinnitamiseks on teel teie postkasti.</p>
        </div>
      </div>
    );
  }
}

export default ThankYou;
