import React, { useEffect } from "react";

import fotograaf from "../../assets/fotograaf.jpg";

const Photographer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="container py-5">
      <h2>Fotograaf Jaana</h2>
      <div className="row white-wrapper">
        <div className="col-lg-6 col-md-12 ">
          <p>
            <b>Otsid fotograafi jäädvustamaks lapse</b> aasta kõige olulisemat
            sündmust - tema sünnipäeva? Pildistan heameelega teie olulist päeva
            Sweetheart mängutoas selleks, et saaksite seda päeva nautida ilma
            muretsemata, kas kõik saab pildile.
          </p>
          <p>
            Olen jäädvustanud erinevaid sündmusi juba pea <b>20 aastat</b>.
            Eriliseks lemmikuks on lapsed ja nende vahetud emotsioonid.
          </p>
          <p>
            <b>Pildistamise esimene tund maksab</b> 150 eurot ja iga järgnev
            tund 100 eurot. Peale fotosessiooni saate galerii lingi 100-400
            pildiga. Lõplik piltide arv sõltub pildistamise sessiooni pikkusest,
            külaliste arvust ja muudest asjaoludest. Töödeldud piltidega galerii
            linki saate soovi korral lahkelt jagada külaliste ja sõpradega, kes
            peost osa said. Pildid on kõrge kvaliteediga ja alla laetavad
            galeriist. Nii on teil mugav peopilte hiljem välja printida ja
            albumisse lisada.
          </p>
          <p>
            Minu <b>töödega saab tutvuda</b> aadressil{" "}
            <a
              className="black-link"
              href="https://www.fotograaftallinnas.ee"
              target="_blank"
            >
              www.fotograaftallinnas.ee
            </a>
          </p>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic"
            src={fotograaf}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
      </div>
    </div>
  );
};

export default Photographer;
